import React from "react";
import ClientsData from "../components/ClientsData";
import RootLayout from "../components/RootLayout";

const ClientDetails = () => {
    return (
        <RootLayout containerClasses="!p-5">
            <ClientsData />
        </RootLayout>
    )
}

export default ClientDetails;