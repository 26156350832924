import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import VirtualizedTable from "../components/VirtualizedTable";
import VirtualizedGrid from "../components/VirtualizedGrid";
import { TableRows, ViewModule } from "@mui/icons-material";

const VirtualizedDataRenderer = (props) => {
    const { loadMoreHandler, title, itemContent, rowContent, data, columns } = props;
    const [gridEnabled, setGridEnabled] = useState(true);
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        filterData();
    }, [searchTerm]);

    const filterData = () => {
        if (!searchTerm.trim()) {
            setFilteredData(data);
        } else {
            const filtered = data.filter((item) =>
                Object.values(item).some((value) =>
                    String(value).toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const fetchMoreData = async (page) => {
        const data = await loadMoreHandler(page);
        return data;
        
    };

    const renderContent = useCallback(() => {
        if (gridEnabled) {
            return (
                <VirtualizedGrid
                    initialData={filteredData}
                    containerClasses="!h-full"
                    itemContent={itemContent}
                    loadMore={fetchMoreData}
                />
            );
        } else {
            return (
                <VirtualizedTable
                    columns={columns}
                    initialRows={filteredData}
                    rowContent={rowContent}
                    containerClasses="h-full w-full"
                    loadMore={fetchMoreData}
                />
            );
        }
    }, [filteredData, gridEnabled]);

    return (
        <Box className="flex flex-col gap-4 h-full">
                <Box className="flex-wrap flex justify-between items-center px-5 max-sm:px-2 pt-5">
                    <Typography sx={{ fontWeight: "bold" }} variant="h6">
                        {title}
                    </Typography>
                    <Box className="flex justify-end gap-2">
                        <TextField
                            type="search"
                            size="small"
                            placeholder="Search"
                            variant="outlined"
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <Button
                            className="!px-5"
                            startIcon={gridEnabled ? <ViewModule /> : <TableRows />}
                            onClick={() => setGridEnabled(!gridEnabled)}>{gridEnabled ? "Table" : "Grids"}</Button>
                    </Box>
                </Box>
                <Box className="h-full">{renderContent()}</Box>
            </Box>
    );
};

export default VirtualizedDataRenderer;
