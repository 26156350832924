import { operationsClient } from "../service/apolloClientService";
import { useMutation } from "@apollo/client";
import { addConnectedStoreQuery } from "../utils/graphqlQueries/connectedStore";
import { useNavigate } from "react-router-dom";

const useOnboardStore = (props) => {
  const navigate = useNavigate();
  const [initiateAddConnectedStore, onboardedStoreDetails] = useMutation(addConnectedStoreQuery, {
    client: operationsClient,
  });
  const initiateAddStore = async (args) => {
    await initiateAddConnectedStore({ variables: { ...args } });
    alert("Store onboarded successfully");
    navigate("/connected-stores")
  };
  return {
    onboardedStoreDetails,
    initiateAddStore
  };
};

export default useOnboardStore;
