const businessSectors = [
    "Men's Wear;Includes all types of men's clothing (casual, formal, sportswear, etc.):mens-wear",
    "Women's Wear;Includes all types of women's clothing (casual, formal, lingerie, maternity, etc.).:womens-wear",
    "Kids' Wear;Includes all types of clothing for children (casual, formal, activewear, etc.).:kids-wear",
    "Unisex Clothing;Clothing suitable for all genders.:unisex-clothing",
    "Sportswear & Activewear;Includes all athletic and fitness-related clothing.:sports-ative-wear",
    "Outerwear;Includes jackets, coats, and weather-appropriate clothing.:outwear",
    "Workwear & Uniforms;Includes professional and industry-specific clothing.:workwear-uniforms",
    "Luxury & Designer Clothing;High-end, bespoke, or designer clothing.:luxury-clothing",
    "Sustainable & Eco-friendly Clothing;Clothing made from sustainable materials or processes.:sustainable-eco-clothing"
]
const businessSegments = [
    "E-commerce:ecommerce",
    "Retail:retail",
    "Wholesale:wholesale",
    "Distributor:distributor",
    "Franchise:franchise",
    "Exporter:exporter",
]
const specialtyAndNiche = [
    "Formal & Business Wear;Includes all types of formal, business, and professional clothing.:formal-business-wear",
    "Casual & Everyday Wear;Includes casual, loungewear, and everyday clothing.:casual-everyday-wear",
    "Activewear & Sportswear;Clothing designed for athletic and fitness activities.:activewear-sportswear",
    "Luxury & Designer Fashion;High-end, bespoke, and designer clothing.:luxury-designer-fashion",
    "Sustainable & Eco-friendly Fashion;Clothing made from sustainable or recycled materials.:sustainable-eco-friendly-fashion",
    "Ethnic & Cultural Wear;Includes traditional and cultural clothing.:ethnic-cultural-wear",
    "Maternity & Nursing Wear;Clothing specifically designed for expectant and new mothers.:maternity-nursing-wear",
    "Plus Size & Adaptive Fashion;Includes clothing designed for larger sizes or adaptive needs.:plus-size-adaptive-fashion",
    "Outerwear & Seasonal Wear;Includes jackets, coats, and clothing for various weather conditions.:outerwear-seasonal-wear",
    "Accessories & Footwear;Includes fashion accessories and related footwear.:accessories-footwear",
    "Vintage & Retro Fashion;Clothing inspired by past decades.:vintage-retro-fashion",
    "Custom & Tailored Clothing;Made-to-measure or bespoke clothing services.:custom-tailored-clothing",
    "Kids’ & Teen Fashion;Clothing designed for children and teenagers.:kids-teen-fashion"
];
const onboardingStatus = [
    "Pending:pending",
    "Completed:completed",
    "Declined:declined"
]

export {
    businessSectors,
    businessSegments,
    specialtyAndNiche,
    onboardingStatus
}