import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Button, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { EditTwoTone, LaunchTwoTone, PhoneTwoTone, Schedule } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ClientDataDialog from "./ClientDataDialog";
import { formattedDate } from "../utils/utils";

const useStyle = makeStyles({
  flexElem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  greyText: {
    color: "#555"
  },
  timeShow: {
    padding: "3px 10px",
    background: "#ebebeb",
    borderRadius: "1rem",
  }
});

const TableClientData = (props) => {
  const {
    entryDate,
    clientName,
    projectDescription,
    state,
    city,
    siteAddress,
    mainSector,
    speciality,
    size,
    clientContactName,
    designation,
    contact,
    emailId,
    officeAddress,
    remindDate,
    followUpRemarks,
    conclusion,
  } = props;
  const navigate = useNavigate();
  const classes = useStyle();
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  }
  const editForm = () => {
    navigate("/onboard-store-form/edit", { state: props });
  }
  return (
    <>
      <TableRow>
        <TableCell>{clientName}</TableCell>
        <TableCell>{city}, {state}</TableCell>
        <TableCell>{projectDescription}</TableCell>
        <TableCell>
          <Box>
            <Typography variant="caption" className={classes.greyText}>
              <b>Main:</b> {mainSector}
            </Typography>,
            <Typography variant="caption" className={classes.greyText}>
              <b> Speciality:</b> {speciality}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{size} Sq.ft.</TableCell>
        <TableCell>{formattedDate(remindDate)}</TableCell>
        <TableCell align="right">
          <IconButton
            color="default"
            edge="start"
            size="small"
            onClick={editForm}
            sx={{ mr: { lg: 1 } }}
          >
            <EditTwoTone />
          </IconButton>
          <IconButton
            color="info"
            edge="start"
            size="small"
            sx={{ mr: { lg: 1} }}
            onClick={toggleDialog}
          >
            <LaunchTwoTone />
          </IconButton>
          <IconButton
            color="secondary"
            edge="start"
            size="small"
            onClick={() => {
              window.open(`tel:${contact}`, "_self");
            }}
          >
            <PhoneTwoTone />
          </IconButton>
        </TableCell>
      </TableRow>
      <ClientDataDialog dialogOpen={dialogOpen} toggleDialog={toggleDialog} {...props} />
    </>
  )
}

export default TableClientData;