import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import AppBarComponent from "../components/AppBarComponent";
import { Alert, AlertTitle, Box, CircularProgress, Grid, Typography } from "@mui/material";
import ReminderCard from "../components/ReminderCard";

const useStyle = makeStyles({
    rootContainer: {
    }
})
const Reminders = () => {
    const classes = useStyle();
    const [clientData, setClientData] = useState();
    const [error, setError] = useState();

    const getData = () => {
        
    }

    useEffect(() => {
        getData();
    }, [])

    if (error) {
        return (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
            </Alert>
        )
    }

    if (!clientData || clientData === null) {
        return (
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <div className={classes.rootContainer}>
            <AppBarComponent />
            <Grid style={{ justifyContent: "center" }} container>
                <Grid item xs={11} sm={9} md={7} lg={6}>
                    <Typography sx={{ fontWeight: "bold", textAlign: "center", mt: 3, mb: 2 }} variant="h4" gutterBottom>
                        Reminders <span style={{ fontWeight: "100", color: "#8a8a8a", fontSize: "1.5rem" }}>(this week)</span>
                    </Typography>
                    {!clientData?.length ? (
                        <Alert severity="info">
                            <AlertTitle>No data</AlertTitle>
                        </Alert>
                    ) : (
                        <>
                            {clientData.map((client, index) => (
                                <ReminderCard {...client} key={index} />
                            )
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default Reminders;