import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { Container, Paper, Grid, TextField, Button, Typography, CssBaseline } from '@mui/material';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles({
    rootContainer: {
    }
})

const loginCredentials = [
    { username: "username", password: "password" },
    { username: "demo", password: "demo" },
]

const Login = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = ({username, password}) => {
        let match = loginCredentials.find(match => 
            match.username === username && match.password === password);
        console.log('match', match)
        if (match !== undefined) {
            localStorage.setItem("userLoggedIn", "true");
            navigate("/");
        }
        else alert("User login failed");
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ alignItems: "center", display: "flex", height: "100vh" }}>
            <CssBaseline />
            <Paper elevation={3} style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
                    Login
                </Typography>
                <form style={{ width: '100%', marginTop: 20 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoFocus
                        {...register("username", {
                            required: "Username is required.",
                        })}
                        error={Boolean(errors.username)}
                        helperText={errors.username ? errors.username.message : ""}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        {...register("password", { required: "Password is required." })}
                        error={Boolean(errors.password)}
                        helperText={errors.password ? errors.password.message : ""}
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        style={{ marginTop: 20 }}
                    >
                        Sign In
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}

export default Login;