import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const useStyle = makeStyles({
  flexElem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gridGap: "1rem",
    flexWrap: "wrap"
  },
  filterSection: {
    width: "100%",
    backgroundColor: "#fff",
  }
});

const FilterSection = ({ toggleFilter, apply, filters }) => {
  const classes = useStyle();
  const [dataFilters, setDataFilters] = useState(filters);
  const clearFilter = () => {
    setDataFilters({})
    apply(dataFilters);
  }
  const applyFilter = () => {
    apply(dataFilters);
    toggleFilter();
  }
  const setDataFilterValue = (updates) => {
    setDataFilters({
      ...dataFilters,
      ...updates
    })
  }

  return (
    <Box sx={{ px: 2, py: 1, borderRadius: 1 }} className={classes.filterSection} >
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        Filter By
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography sx={{ fontWeight: "bold" }} variant="caption" gutterBottom >
            Client name
          </Typography>
          <TextField
            fullWidth
            placeholder="enter client name"
            variant="standard"
            value={dataFilters?.clientName ?? ""}
            onChange={(e) => setDataFilterValue({
              clientName: e.target.value
            })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography sx={{ fontWeight: "bold" }} variant="caption" gutterBottom>
            Location
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                placeholder="in state"
                variant="standard"
                value={dataFilters?.state ?? ""}
                onChange={(e) => setDataFilterValue({
                  state: e.target.value
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                placeholder="in city"
                variant="standard"

                value={dataFilters?.city ?? ""}
                onChange={(e) => setDataFilterValue({
                  city: e.target.value
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography sx={{ fontWeight: "bold" }} variant="caption" gutterBottom>
            Size
          </Typography>
          <TextField
            fullWidth
            type="number"
            placeholder="size in sq. ft."
            variant="standard"
            value={dataFilters?.size ?? ""}
            onChange={(e) => setDataFilterValue({
              size: e.target.value
            })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography sx={{ fontWeight: "bold" }} variant="caption" gutterBottom>
            Sector
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                placeholder="in main sector"
                variant="standard"
                value={dataFilters?.mainSector ?? ""}
                onChange={(e) => setDataFilterValue({
                  mainSector: e.target.value
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                placeholder="in speciality"
                variant="standard"
                value={dataFilters?.speciality ?? ""}
                onChange={(e) => setDataFilterValue({
                  speciality: e.target.value
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.flexElem} sx={{ my: 1, mt: 2, justifyContent: { xs: "space-between", sm: "flex-end" } }}>
        <Box>
          <Button color="info" size="small" sx={{ fontWeight: "bold", mr: 1 }} onClick={clearFilter}>Clear</Button>
          <Button color="error" size="small" sx={{ fontWeight: "bold" }} onClick={toggleFilter}>Close</Button>
        </Box>
        <Button disableElevation color="secondary" variant="contained" size="small" sx={{fontWeight: "bold"}} onClick={applyFilter}>
          Apply
        </Button>
      </Box>
    </Box>
  )
}

export default FilterSection;