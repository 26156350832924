import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';

// Define the Virtuoso components
const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table size="small" {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const VirtualizedTable = ({ columns, initialRows, containerClasses, loadMore, rowContent }) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available

  useState(() => {
    setRows(initialRows)
    setPage(1)
  }, [initialRows])

  const handleLoadMore = async () => {
    if (loading || !hasMore) return; // Prevent further loading if already loading or no more data
    setLoading(true);

    const newRows = await loadMore(page); // Fetch more data
    if (newRows.length > 0) {
      setRows((prevRows) => [...prevRows, ...newRows]); // Append the new rows
      setPage((prevPage) => prevPage + 1); // Increment page
    } else {
      setHasMore(false); // If no more data is available
    }

    setLoading(false);
  };

  const fixedHeaderContent = () => (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <Paper className={containerClasses}>
      <TableVirtuoso
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
        endReached={handleLoadMore} // Load more data when end is reached
      />
    </Paper>
  );
};

export default VirtualizedTable;
