import React from 'react'
import AppBarComponent from './AppBarComponent';
import { Box, Typography } from '@mui/material';

const RootLayout = (props) => {
    const { children, containerClasses } = props;
    return (
        <Box>
            <AppBarComponent />
            <Box className={`${containerClasses}`}>
                {children}
            </Box>
        </Box>
    )
}

export default RootLayout