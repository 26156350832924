import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBackHome = () => {
    navigate('/');
  };

  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '100vh', 
        textAlign: 'center' 
      }}
    >
      <Typography variant="h1" component="h1" sx={{ fontWeight: 'bold', fontSize: '8rem' }}>
        404
      </Typography>
      <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 2 }}>
        Oops! Page not found.
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        The page you are looking for doesn't exist or has been moved.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={goBackHome}
        sx={{ padding: '10px 20px', fontSize: '1rem' }}
      >
        Go Back Home
      </Button>
    </Container>
  );
};

export default PageNotFound;
