import React, { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Stepper, Step, StepLabel, Button, TextField, Box,
    StepContent,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import { businessSectors, businessSegments, onboardingStatus, specialtyAndNiche } from '../common/constants';
import MultiSelectField from './MultiSelectField';
import indianStates from '../common/states';

// Styles for Tailwind CSS with MUI integration
const StepContainer = styled(StepContent)({
    '& .MuiTextField-root': {
        marginBottom: '1rem',
    },
});

const steps = [
    {
        label: 'Store Information',
        caption: 'Store name, description, and sector.'
    },
    {
        label: 'Online Presence',
        caption: 'Website and social media links.'
    },
    {
        label: 'Address',
        caption: 'Store location details.'
    },
    {
        label: 'Contact Information',
        caption: 'Store Point of contact details.'
    },
    {
        label: 'Follow-up & Remarks',
        caption: 'Follow-up details and notes for future actions.'
    },
    {
        label: 'Conclusion',
        caption: 'Final thoughts & Status of onboarding process.'
    }
];

const OnboardStoreSteps = (props) => {
    const { save } = props;
    const [activeStep, setActiveStep] = useState(0);
    const { handleSubmit, control, formState: { errors }, trigger } = useForm({
        mode: 'onTouched',
    });

    const onNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const onBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const onSubmit = (data) => {
        save(data);
    };

    const renderActions = useCallback(() => {
        return (
            <div className="flex justify-between mt-6">
                <Button disabled={activeStep === 0} onClick={onBack}>
                    Back
                </Button>

                {activeStep === steps.length - 1 ? (
                    <Button type="submit" variant="contained" color="primary">
                        Finish
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={onNext}>
                        Next
                    </Button>
                )}
            </div>
        );
    }, [activeStep]);

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StepContainer>
                        <Box>
                            <Controller
                                name="storeName"
                                control={control}
                                rules={{ required: 'Store Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Store Name"
                                        variant="standard"
                                        error={!!errors.storeName}
                                        helperText={errors.storeName?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <MultiSelectField
                                name="businessSector"
                                control={control}
                                controlLabel={"Business Sector"}
                                optionsData={businessSectors}
                                errors={errors}
                            />
                            <MultiSelectField
                                name="businessSegments"
                                controlLabel={"Business Segment"}
                                control={control}
                                optionsData={businessSegments}
                                errors={errors}
                            />
                            <MultiSelectField
                                name="specialtyAndNiche"
                                controlLabel={"Speciality & Niche"}
                                control={control}
                                optionsData={specialtyAndNiche}
                                errors={errors}
                            />
                        </Box>
                        <Box>
                            {renderActions()}
                        </Box>
                    </StepContainer>
                );
            case 1:
                return (
                    <StepContainer>
                        <Box>
                            <Controller
                                name="website"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Website URL"
                                        variant="standard"
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="instagram"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Instagram URL"
                                        variant="standard"
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="otherSocialMedia"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Other Social Media URL"
                                        variant="standard"
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            {renderActions()}
                        </Box>
                    </StepContainer>
                );
            case 2:
                return (
                    <StepContainer>
                        <Box>
                            <Controller
                                name="addressLine1"
                                control={control}
                                rules={{ required: 'Address Line 1 is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 1"
                                        variant="standard"
                                        error={!!errors.addressLine1}
                                        helperText={errors.addressLine1?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="addressLine2"
                                control={control}
                                rules={{ required: 'Address Line 2 is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 2"
                                        variant="standard"
                                        error={!!errors.addressLine2}
                                        helperText={errors.addressLine2?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="city"
                                control={control}
                                rules={{ required: 'City is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="City"
                                        variant="standard"
                                        error={!!errors.city}
                                        helperText={errors.city?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Box className="mb-4">
                                <Controller
                                    name="state"
                                    control={control}
                                    rules={{ required: 'State is required' }}
                                    render={({ field }) => (
                                        <FormControl fullWidth sx={{ mt: 1 }}>
                                            <InputLabel sx={{ ml: "-13px" }} id="state-select-label">State</InputLabel>
                                            <Select
                                                labelId="state-select-label"
                                                variant="standard"
                                                size="small"
                                                value={field.value || ''}  // Ensure correct value handling
                                                onChange={field.onChange}   // Handling change via react-hook-form
                                                error={Boolean(errors.state)}
                                            >
                                                {Object.values(indianStates).map((st, index) => (
                                                    <MenuItem key={index} value={st}>
                                                        {st}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.state && <Typography variant="caption" color="error">{errors.state.message}</Typography>}
                                        </FormControl>
                                    )}
                                />
                            </Box>
                            <Controller
                                name="pincode"
                                control={control}
                                rules={{
                                    required: 'Pincode/Postal Code is required',
                                    pattern: {
                                        value: /^[0-9]{6}$/,
                                        message: 'Invalid pincode',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Pincode/Postal Code"
                                        variant="standard"
                                        error={!!errors.pincode}
                                        helperText={errors.pincode?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="googleMapsUrl"
                                control={control}
                                rules={{
                                    required: 'Google Maps URL is required',
                                    pattern: {
                                        value: /^https?:\/\/(www\.)?(google\.com\/maps|maps\.app\.goo\.gl)\/.*/i,
                                        message: 'Please enter a valid Google Maps URL'
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Google Maps URL"
                                        variant="standard"
                                        error={!!errors.googleMapsUrl}
                                        helperText={errors.googleMapsUrl?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="country"
                                control={control}
                                defaultValue="India" // Setting the default value to "India"
                                rules={{ required: 'Country is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Country"
                                        variant="standard"
                                        error={!!errors.country}
                                        helperText={errors.country?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            {renderActions()}
                        </Box>
                    </StepContainer>
                );
            case 3:
                return (
                    <StepContainer>
                        <Box>
                            <Controller
                                name="primaryContactName"
                                control={control}
                                rules={{ required: 'Primary Contact Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Primary Contact Name"
                                        variant="standard"
                                        error={!!errors.primaryContactName}
                                        helperText={errors.primaryContactName?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="contactEmail"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email"
                                        variant="standard"
                                        error={!!errors.contactEmail}
                                        helperText={errors.contactEmail?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="contactPhoneNumber"
                                control={control}
                                rules={{
                                    required: 'Phone Number is required',
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: 'Please enter a valid 10-digit phone number'
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Phone Number"
                                        variant="standard"
                                        error={!!errors.contactPhoneNumber}
                                        helperText={errors.contactPhoneNumber?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="contactWhatsappNumber"
                                control={control}
                                rules={{
                                    required: 'Whatsapp Number is required',
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: 'Please enter a valid 10-digit whatsapp number'
                                    }
                                }}

                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Whatsapp Number"
                                        variant="standard"
                                        error={!!errors.contactWhatsappNumber}
                                        helperText={errors.contactWhatsappNumber?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="contactDesignation"
                                control={control}
                                rules={{ required: 'Designation/Role is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Designation/Role"
                                        variant="standard"
                                        error={!!errors.contactDesignation}
                                        helperText={errors.contactDesignation?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            {renderActions()}
                        </Box>
                    </StepContainer>
                );
            case 4:
                return (
                    <StepContainer>
                        <Box>
                            <Controller
                                name="remarks"
                                rules={{ required: 'Follow-up Remarks is required' }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Follow-up Remarks"
                                        variant="standard"
                                        multiline
                                        error={!!errors.remarks}
                                        helperText={errors.remarks?.message}
                                        rows={4}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="nextFollowupDate"
                                control={control}
                                rules={{
                                    required: 'Next Follow-up Date is required',
                                    validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const minDate = new Date();
                                        const maxDate = new Date();
                                        minDate.setDate(minDate.getDate() + 2); // Set minimum date to 2 days after today
                                        maxDate.setDate(maxDate.getDate() + 10); // Set maximum date to 10 days after today

                                        if (selectedDate < minDate) {
                                            return 'The follow-up date must be at least 2 days after today';
                                        } else if (selectedDate > maxDate) {
                                            return 'The follow-up date must be within 10 days from today';
                                        }

                                        return true;
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Next Follow-up Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={!!errors.nextFollowupDate}
                                        helperText={errors.nextFollowupDate?.message}
                                        fullWidth
                                        inputProps={{
                                            min: new Date(new Date().setDate(new Date().getDate() + 2)).toISOString().split('T')[0],
                                            max: new Date(new Date().setDate(new Date().getDate() + 10)).toISOString().split('T')[0]
                                        }}
                                    />
                                )}
                            />

                        </Box>
                        <Box>
                            {renderActions()}
                        </Box>
                    </StepContainer>
                );
            case 5:
                return (
                    <StepContainer>
                        <Box>
                            <MultiSelectField
                                name="onboardingStatus"
                                control={control}
                                controlLabel={"Onboarding status"}
                                optionsData={onboardingStatus}
                                multiple={false}
                                errors={errors}
                            />
                            <Controller
                                name="conclusionNotes"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Conclusion/Notes"
                                        variant="standard"
                                        multiline
                                        rows={4}
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            {renderActions()}
                        </Box>
                    </StepContainer>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel optional={
                                (
                                    <Typography variant="caption">{step.caption}</Typography>
                                )
                            }>{step.label}</StepLabel>
                            {index === activeStep && renderStepContent(activeStep)}  {/* Only render current step */}
                        </Step>
                    ))}
                </Stepper>
            </form>
        </Box>
    );
}

export default OnboardStoreSteps;
