import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Button, Divider, Typography } from "@mui/material";
import { Schedule } from "@mui/icons-material";
import ClientDataDialog from "./ClientDataDialog";
import { formattedDate } from "../utils/utils";

const useStyle = makeStyles({
  flexElem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  greyText: {
    color: "#555"
  },
  timeShow: {
    padding: "3px 10px",
    background: "#ebebeb",
    borderRadius: "1rem",
  }
});

const ReminderCard = (props) => {
  const {
    entryDate,
    clientName,
    projectDescription,
    state,
    city,
    siteAddress,
    mainSector,
    speciality,
    size,
    clientContactName,
    designation,
    contact,
    emailId,
    officeAddress,
    remindDate,
    followUpRemarks,
    conclusion,
  } = props;
  const classes = useStyle();
  const [dialogOpen, setDialogOpen] = useState(false);
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  }
  return (
    <>
      <Box sx={{ p: 0, mb: 2, background: "#fff", borderRadius: 3 }}>
        <Box sx={{ mb: 1, px: 3, pt: 1 }} className={classes.flexElem}>
          <Typography variant="h6" sx={{ mb: 0 }}>
            {clientName}
          </Typography>
          <Typography variant="caption" className={classes.greyText}>
            {city}, {state}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Typography variant="body2" gutterBottom>
            {projectDescription}
          </Typography>
          <Typography variant="body2" className={classes.greyText} sx={{ fontWeight: "bold" }}>
            Sector:
          </Typography>
          <Box className={classes.flexElem}>
            <Typography variant="caption" className={classes.greyText}>
              <b>Main:</b> {mainSector}
            </Typography>
            <Typography variant="caption" className={classes.greyText}>
              <b>Speciality:</b> {speciality}
            </Typography>
          </Box>
          <Typography variant="body2" gutterBottom sx={{ my: 1 }}>
            <b>Follow up:</b> I will have to call this cleinth on 09-9-23, he is ready to meet on 6-12-23
          </Typography>
          <Box className={classes.flexElem}>
            <Typography variant="caption" gutterBottom className={`${classes.greyText} ${classes.timeShow}`}>
              <Schedule style={{ fontSize: "1rem", paddingTop: "5px" }} /> {formattedDate(remindDate)}
            </Typography>
            <Typography variant="caption" gutterBottom sx={{ fontWeight: "bold" }}>
              Size:  {size} Sq.ft.
            </Typography>
          </Box>
          <Box className={classes.flexElem} sx={{ justifyContent: "flex-start", gridGap: 5 }}>
            <Button onClick={toggleDialog} variant="contained" color="info" sx={{ fontWeight: "bold", borderRadius: 5, mt: 3 }} disableElevation>View details</Button>
            <Button onClick={() => {
              window.open(`tel:${contact}`, "_self");
            }} variant="contained" color="secondary" sx={{ fontWeight: "bold", borderRadius: 5, mt: 3 }} disableElevation>Call</Button>
          </Box>
        </Box>
      </Box>
      <ClientDataDialog dialogOpen={dialogOpen} toggleDialog={toggleDialog}  {...props} />
    </>
  )
}

export default ReminderCard;