import React, { useCallback } from "react";
import RootLayout from "../components/RootLayout";
import { Box, LinearProgress, TableCell } from "@mui/material";
import StoreDetailCard from "../components/StoreDetailCard";
import VirtualizedDataRenderer from "../components/VirtualizedDataRenderer";
import useConnectedStores from "../hooks/useConnectedStores";
import StoreDetailTableRow from "../components/StoreDetailTableRow";

const ConnectedStores = () => {
    const {
        data,
        loading,
        error,
        loadMore,
        pageSize
    } = useConnectedStores();

    const loadMoreHandler = async (page) => {
        if (!loading && data) {
            const data = await loadMore(page)
            return data;
        }
        return [];
    };
    const renderData = useCallback(() => {
        if (!data) {
            return <LinearProgress color="inherit" />
        }

        const columns = data && data.length > 0 ? Object.keys(data[0]).map((key) => ({
            width: 200,
            label: key.replace(/([A-Z])/g, " $1").toUpperCase(),
            dataKey: key,
        })) : [];
        return (
            <VirtualizedDataRenderer
                loadMoreHandler={loadMoreHandler}
                data={data}
                columns={columns}
                itemContent={(index, item) => (
                    <StoreDetailCard details={item} />
                )}
                rowContent={(index, row) => (
                    <StoreDetailTableRow columns={columns} row={row} />
                )}
                title={"Connected Stores"}
            />
        )
    }, [data])
    return (
        <RootLayout>
            <Box className="flex flex-col gap-4 h-[calc(100vh-64px)]">
                {renderData()}
            </Box>
        </RootLayout>
    );
};

export default ConnectedStores;
