import { useState, useEffect, forwardRef } from "react";
import { VirtuosoGrid } from "react-virtuoso";

// Reusable Grid Components
const GridComponents = {
  List: forwardRef(({ style, children, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      style={{
        display: "flex",
        flexWrap: "wrap",
        ...style,
      }}
    >
      {children}
    </div>
  )),
  Item: ({ children, ...props }) => (
    <div
      {...props}
      className="p-2 w-full lg:w-1/2 xl:w-1/3 box-border"
    >
      {children}
    </div>
  ),
};

// Reusable VirtuosoGrid Component with "Load More" and "End Reached"
const VirtualizedGrid = ({ initialData, containerClasses, itemContent, loadMore }) => {
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(1); // Start from page 1
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Keep track if there's more data

  const handleLoadMore = async () => {
    if (loading || !hasMore) return; // Stop loading if already loading or no more data

    setLoading(true);
    
    const newData = await loadMore(page); // Fetch more data
    if (newData.length > 0) {
      setData((prevData) => [...prevData, ...newData]); // Append new data
      setPage((prevPage) => prevPage + 1); // Increment page
    } else {
      setHasMore(false); // If no more data, stop loading further
    }

    setLoading(false);
  };

  return (
    <VirtuosoGrid
      className={containerClasses}
      totalCount={data.length}
      components={GridComponents}
      itemContent={(index) => itemContent(index, data[index])}
      endReached={handleLoadMore} // Trigger load more when the end is reached
    />
  );
};

export default VirtualizedGrid;
