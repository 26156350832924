
const formattedDate = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    return dateString;
}
const parseData = (valueString, separator = ":") => {
    return valueString.split(separator);
}

export { formattedDate, parseData }