import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { Card, CardContent } from "@mui/material";

const useStyle = makeStyles({
  flexElem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  greyText: {
    color: "#555"
  },
  timeShow: {
    padding: "3px 10px",
    background: "#ebebeb",
    borderRadius: "1rem",
  }
});

const StoreDetailCard = (props) => {
  const {details} = props;
  
  if(!details) return <></>
  return (
    <>
      <Card elevation={3}>
        <CardContent>
        {(Object.keys(details) ?? []).map((it, key) => <p key={key}>{it}:{details[it]}</p>)}
        </CardContent>
      </Card>
    </>
  )
}

export default StoreDetailCard;