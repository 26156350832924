import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  RouterProvider,
} from "react-router-dom";
import router from './routes/router';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(231, 67, 38)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#e9ca54',
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
