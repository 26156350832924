import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { operationsClient } from "../service/apolloClientService";
import { listConnectedStoresQuery } from "../utils/graphqlQueries/connectedStore";

const useConnectedStores = () => {
    const [pageSize, setPageSize] = useState(10);
    const [filters, setFilters] = useState({});
    const [sorting, setSorting] = useState({});
    const [initialData, setInitialData] = useState();

    const { data, loading, error, fetchMore } = useQuery(listConnectedStoresQuery, {
        variables: {
            page: 1,
            pageSize,
            filters,
            sorting,
        },
        client: operationsClient,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if(!initialData) {
            setInitialData(data?.listConnectedStores)
        }
    }, [data, initialData])

    const loadMore = async (page) => {
        return new Promise((resolve, reject) => {
            fetchMore({
                variables: {
                    page: page + 1,
                    pageSize,
                    filters,
                    sorting,
                },
            }).then(({data}) => {
                if(!data?.listConnectedStores.length) {
                    return []
                }
                resolve(data?.listConnectedStores)
            }).catch((error) => {
                reject(error)
                
            })
        })
        
    }

    return {
        data: initialData,
        loading: loading,
        error,
        loadMore,
        pageSize
    };
};

export default useConnectedStores;
