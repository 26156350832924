import { TableCell } from "@mui/material";
import React from "react";

const StoreDetailTableRow = ({ columns, row }) => {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align={column.numeric || false ? "right" : "left"}
        >
          {row[column.dataKey]}
        </TableCell>
      ))}
    </React.Fragment>
  );
};

export default StoreDetailTableRow;
