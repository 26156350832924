import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import config from "../config";
import { onError } from "@apollo/client/link/error";
import apiRoutes from "../config/apiRoutes";

const logout = () => {
  console.log("logged out");
}
const createApolloClient = (service) => {
  const httpLink = new HttpLink({
      uri: `${config.API_URL}${service}`,
      credentials: 'include',
  });
  const handleError = onError((params) => {
      const { networkError } = params;
      if (networkError?.statusCode === 401) logout();
  })
  return new ApolloClient({
      cache: new InMemoryCache(),
      link: handleError.concat(httpLink)
  });
};

export const operationsClient = createApolloClient(apiRoutes.operationsService);

export default createApolloClient;