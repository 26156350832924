import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { CalendarMonthTwoTone, EditTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { formattedDate } from "../utils/utils";

const useStyle = makeStyles({
  flexElem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  greyText: {
    color: "#555"
  },
  timeShow: {
    padding: "3px 10px",
    background: "#ebebeb",
    borderRadius: "1rem",
  }
});

const ClientDataDialog = (props) => {
  const { 
    dialogOpen,
    toggleDialog,
    entryDate,
    clientName,
    projectDescription,
    state,
    city,
    siteAddress,
    mainSector,
    speciality,
    size,
    clientContactName,
    designation,
    contact,
    emailId,
    officeAddress,
    remindDate,
    followUpRemarks,
    conclusion,
  } = props;
  const navigate = useNavigate();
  const classes = useStyle();

  const editForm = () => {
    const { dialogOpen, toggleDialog, ...rest } = props;
    navigate("/onboard-store-form/edit", { state: rest });
  }

  return (
    <Dialog
      open={dialogOpen}
      keepMounted
      onClose={toggleDialog}
      fullWidth
      fullScreen={window.innerWidth < 714 ? true : false}
    >
      <Typography variant="caption" gutterBottom sx={{ fontWeight: "bold", textAlign: "center", background: "#cfcfcf", padding: "2px" }}>
        Entry date: {formattedDate(entryDate)}
      </Typography>
      <DialogTitle sx={{ pt: 0, pb: 1 }}>
        <IconButton
          color="default"
          edge="start"
          size="small"
          sx={{ float: "right" }}
          onClick={editForm}
        >
          <EditTwoTone />
        </IconButton>
        {clientName}
        <Typography component={"p"} variant="caption" className={classes.greyText}>
          {city}, {state}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ pb: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Project details
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {projectDescription}
          </Typography>
          <Typography variant="body2" className={classes.greyText} sx={{ fontWeight: "bold", textDecoration: "underline" }}>
            Sector:
          </Typography>
          <Box className={classes.flexElem}>
            <Typography variant="caption" className={classes.greyText}>
              <b>Main:</b> {mainSector}
            </Typography>
            <Typography variant="caption" className={classes.greyText}>
              <b>Speciality:</b> {speciality}
            </Typography>
          </Box>
          <Typography variant="body2" className={classes.greyText} sx={{ fontWeight: "bold", textDecoration: "underline" }}>
            Site address:
          </Typography>
          <Box className={classes.flexElem}>
            <Typography variant="caption" className={classes.greyText}>
              {siteAddress}
            </Typography>
          </Box>
          <Box className={classes.flexElem} sx={{ mt: 1 }}>
            <Typography variant="caption" gutterBottom className={`${classes.greyText} ${classes.timeShow}`}>
              <CalendarMonthTwoTone style={{ fontSize: "1rem", paddingTop: "5px" }} /> {formattedDate(remindDate)}
            </Typography>
            <Typography variant="caption" gutterBottom sx={{ fontWeight: "bold" }}>
              Size:  {size} Sq.ft.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Client Contact
          </Typography>
          <Box sx={{ py: "0.2rem" }}>
            <Typography component={"p"} variant="caption" className={classes.greyText} sx={{ pb: "0.2rem" }}>
              <b>Name:</b> {clientContactName}
            </Typography>
            <Typography component={"p"} variant="caption" className={classes.greyText} sx={{ pb: "0.2rem" }}>
              <b>Designation:</b> {designation}
            </Typography>
            <Typography component={"p"} variant="caption" className={classes.greyText} sx={{ pb: "0.2rem" }}>
              <b>Contact:</b> {contact}
            </Typography>
            <Typography component={"p"} variant="caption" className={classes.greyText} sx={{ pb: "0.2rem" }}>
              <b>Email:</b> {emailId}
            </Typography>
            <Typography component={"p"} variant="caption" className={classes.greyText}>
              <b>Office address:</b>
            </Typography>
            <Typography component={"p"} variant="caption" className={classes.greyText}>
              {officeAddress}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Follow up
          </Typography>
          <Typography variant="body2" gutterBottom>
            {followUpRemarks}
          </Typography>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Conclusion
          </Typography>
          <Typography variant="body2" gutterBottom>
            {conclusion}
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="secondary" variant="contained" size="small" onClick={() => {
          window.open(`tel:${contact}`, "_self");
        }} sx={{ fontWeight: "bold", mr: 1 }}>Call</Button>
        <Button color="secondary" onClick={toggleDialog} sx={{ fontWeight: "bold" }}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientDataDialog;