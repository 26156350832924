import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

const MultiSelectField = ({ control, controlLabel, name, optionsData, errors }) => {
  const options = optionsData.map(item => {
    const [labelCaption, value] = item.split(':');
    let label = labelCaption;
    let caption = '';

    if (labelCaption.includes(';')) {
      [label, caption] = labelCaption.split(';');
    }

    return { label, caption, value };
  });
  return (
    <Box className="mb-4">
      <Controller
        name={name}
        control={control}
        rules={{ required: `${controlLabel} is required` }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl variant="standard" fullWidth error={!!errors[name]}>
            <InputLabel>{controlLabel}</InputLabel>
            <Select
              multiple
              value={value || []}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Typography key={value} variant="body2">
                      {options.find((option) => option.value === value)?.label},
                    </Typography>
                  ))}
                </Box>
              )}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body1">{option.label}</Typography>
                    {option.caption && (
                      <Typography variant="body2" color="textSecondary">
                        {option.caption}
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
            {errors[name] && (
              <p className="text-red-500 text-sm">{errors[name]?.message}</p>
            )}
          </FormControl>
        )}
      />
    </Box>
  );
};

export default MultiSelectField;
