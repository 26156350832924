import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { Alert, AlertTitle, Box, Button, CircularProgress, Grid, IconButton, Pagination, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FileDownloadTwoTone, FilterAltTwoTone, GridViewTwoTone, ViewListTwoTone } from "@mui/icons-material";
import TableClientData from "./TableClientData";
import FilterSection from "./FilterSection";
import StoreDetailCard from "./StoreDetailCard";

const useStyle = makeStyles({
  flexElem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  }
});

const data = {
  items: [
    {
      id: "1",
      entryDate: "2024-01-01",
      clientName: "ABC Corp",
      projectDescription: "Office building construction",
      state: "California",
      city: "Los Angeles",
      siteAddress: "123 Main St",
      mainSector: "Construction",
      speciality: "Commercial",
      size: "5000 sq ft",
      clientContactName: "John Doe",
      designation: "Project Manager",
      contact: "555-1234",
      emailId: "john.doe@abccorp.com",
      officeAddress: "456 Corporate Dr",
      remindDate: "2024-02-15",
      followUpRemarks: "Waiting for design approval",
      conclusion: "In progress",
    },
    {
      id: "2",
      entryDate: "2024-01-05",
      clientName: "XYZ Ltd",
      projectDescription: "Retail store renovation",
      state: "Texas",
      city: "Dallas",
      siteAddress: "789 Commerce St",
      mainSector: "Renovation",
      speciality: "Retail",
      size: "3000 sq ft",
      clientContactName: "Jane Smith",
      designation: "Operations Head",
      contact: "555-5678",
      emailId: "jane.smith@xyzltd.com",
      officeAddress: "101 Business Blvd",
      remindDate: "2024-02-20",
      followUpRemarks: "Materials ordered",
      conclusion: "In progress",
    },
    {
      id: "3",
      entryDate: "2024-01-10",
      clientName: "DEF Inc",
      projectDescription: "Warehouse construction",
      state: "Florida",
      city: "Miami",
      siteAddress: "234 Logistics Ave",
      mainSector: "Construction",
      speciality: "Industrial",
      size: "10000 sq ft",
      clientContactName: "Alex Johnson",
      designation: "Logistics Manager",
      contact: "555-9101",
      emailId: "alex.johnson@definc.com",
      officeAddress: "789 Supply Ln",
      remindDate: "2024-03-01",
      followUpRemarks: "Finalizing blueprints",
      conclusion: "Pending",
    },
    {
      id: "4",
      entryDate: "2024-01-15",
      clientName: "GHI Group",
      projectDescription: "Hotel interior design",
      state: "Nevada",
      city: "Las Vegas",
      siteAddress: "567 Resort Rd",
      mainSector: "Interior Design",
      speciality: "Hospitality",
      size: "2000 sq ft",
      clientContactName: "Emily Davis",
      designation: "Design Director",
      contact: "555-1122",
      emailId: "emily.davis@ghigroup.com",
      officeAddress: "222 Entertainment Dr",
      remindDate: "2024-03-10",
      followUpRemarks: "Design drafts sent",
      conclusion: "In progress",
    },
    {
      id: "5",
      entryDate: "2024-01-20",
      clientName: "JKL Enterprises",
      projectDescription: "Office space remodeling",
      state: "New York",
      city: "New York",
      siteAddress: "100 Wall St",
      mainSector: "Remodeling",
      speciality: "Corporate",
      size: "6000 sq ft",
      clientContactName: "Michael Lee",
      designation: "Facilities Manager",
      contact: "555-3344",
      emailId: "michael.lee@jkl.com",
      officeAddress: "123 Manhattan Ave",
      remindDate: "2024-02-25",
      followUpRemarks: "Awaiting contractor bid",
      conclusion: "Pending",
    },
    {
      id: "6",
      entryDate: "2024-01-25",
      clientName: "MNO Industries",
      projectDescription: "Factory extension",
      state: "Ohio",
      city: "Cleveland",
      siteAddress: "321 Industrial Way",
      mainSector: "Construction",
      speciality: "Manufacturing",
      size: "12000 sq ft",
      clientContactName: "Sarah Wilson",
      designation: "Operations Manager",
      contact: "555-4455",
      emailId: "sarah.wilson@mno.com",
      officeAddress: "567 Corporate Cir",
      remindDate: "2024-03-05",
      followUpRemarks: "Site survey completed",
      conclusion: "In progress",
    },
    {
      id: "7",
      entryDate: "2024-02-01",
      clientName: "PQR Holdings",
      projectDescription: "Luxury apartment development",
      state: "Georgia",
      city: "Atlanta",
      siteAddress: "456 Uptown Blvd",
      mainSector: "Real Estate",
      speciality: "Residential",
      size: "8000 sq ft",
      clientContactName: "Chris Miller",
      designation: "Real Estate Developer",
      contact: "555-5566",
      emailId: "chris.miller@pqr.com",
      officeAddress: "789 Highrise Ln",
      remindDate: "2024-03-15",
      followUpRemarks: "Financing approved",
      conclusion: "In progress",
    },
    {
      id: "8",
      entryDate: "2024-02-05",
      clientName: "STU Solutions",
      projectDescription: "Office park development",
      state: "Illinois",
      city: "Chicago",
      siteAddress: "901 Business Park Dr",
      mainSector: "Construction",
      speciality: "Corporate",
      size: "7000 sq ft",
      clientContactName: "Rachel Adams",
      designation: "Project Coordinator",
      contact: "555-6677",
      emailId: "rachel.adams@stu.com",
      officeAddress: "123 Finance St",
      remindDate: "2024-03-20",
      followUpRemarks: "Contract signed",
      conclusion: "In progress",
    },
    {
      id: "9",
      entryDate: "2024-02-10",
      clientName: "VWX Solutions",
      projectDescription: "Retail chain expansion",
      state: "Washington",
      city: "Seattle",
      siteAddress: "456 Commerce Way",
      mainSector: "Retail",
      speciality: "Commercial",
      size: "4000 sq ft",
      clientContactName: "David Brown",
      designation: "Expansion Manager",
      contact: "555-7788",
      emailId: "david.brown@vwx.com",
      officeAddress: "321 Market St",
      remindDate: "2024-03-25",
      followUpRemarks: "Awaiting permits",
      conclusion: "Pending",
    },
    {
      id: "10",
      entryDate: "2024-02-15",
      clientName: "YZA Corporation",
      projectDescription: "IT office renovation",
      state: "Colorado",
      city: "Denver",
      siteAddress: "789 Tech Plaza",
      mainSector: "IT Services",
      speciality: "Corporate",
      size: "2500 sq ft",
      clientContactName: "Kevin Thompson",
      designation: "Tech Lead",
      contact: "555-8899",
      emailId: "kevin.thompson@yza.com",
      officeAddress: "987 Innovation Way",
      remindDate: "2024-03-30",
      followUpRemarks: "Waiting for IT infrastructure setup",
      conclusion: "In progress",
    }
  ],
  totalPages: 1,
};

const ClientsData = () => {
  const classes = useStyle();
  const [viewType, setViewType] = useState("list"); // grid or list
  const [filterOpen, setFilterOpen] = useState(false);
  const [clientData, setClientData] = useState(data.items);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataFilters, setDataFilters] = useState({});

  const switchView = (view) => () => {
    setViewType(view);
  }
  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  }
  useEffect(() => {
    // getData();
  }, [currentPage, dataFilters])

  const applyFilter = (filters) => {
    let filtersRefined = Object.entries(filters).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
    setCurrentPage(1);
    setDataFilters({
      ...filtersRefined
    });
  }

  // useEffect(() => {
  //   document.addEventListener('scroll', () => {
  //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
  //       console.log("bottom reached");
  //       setCurrentPage(currentPage + 1)
  //     }
  //   });
  // }, [])


  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    )
  }

  if (!clientData || clientData === null) {
    return (
      <Box sx={{ display: 'flex', justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box>
      <Box sx={{ mb: 3, justifyContent: { xs: "space-evenly", sm: "space-between" } }} className={classes.flexElem}>
        <Typography sx={{ fontWeight: "bold" }} variant="h4">
          Connected Stores
        </Typography>
        <Box>
          <IconButton size="large"
            onClick={toggleFilter}
            color={filterOpen ? "info" : "default"}
            style={filterOpen ? { background: "#fff", borderBottomLeftRadius: "0", borderBottomRightRadius: "0" } : {}}
          >
            <FilterAltTwoTone />
          </IconButton>
          <span style={{ margin: "0px 0.5rem" }}>|</span>
          <Button onClick={() => {  }} size="large" variant="text" sx={{ textTransform: "none" }} startIcon={<FileDownloadTwoTone />}>
            Excel download
          </Button>
          <span style={{ margin: "0px 0.5rem" }}>|</span>
          <span style={{ display: "inline-block" }}>
            <IconButton
              size="large"
              color={viewType === "grid" ? "secondary" : "default"}
              onClick={switchView("grid")}>
              <GridViewTwoTone />
            </IconButton>
            <IconButton size="large" color={viewType === "list" ? "secondary" : "default"} onClick={switchView("list")}>
              <ViewListTwoTone />
            </IconButton>
          </span>
        </Box>
        {filterOpen && (
          <FilterSection toggleFilter={toggleFilter} filters={dataFilters} apply={applyFilter} />
        )}
      </Box>
      {!clientData?.length ? (
        <Alert severity="info">
          <AlertTitle>No data</AlertTitle>
        </Alert>
      ) : (
        <>
          {viewType === "list" && (
            <Box>
              <TableContainer component={Paper}>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Client name</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Location</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Project description</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Sector</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Size</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Reminder date</TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {clientData.map((client) => (
                    <TableClientData {...client} key={client.id} />
                  )
                  )}
                </Table>
              </TableContainer>
              <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                <Pagination count={totalPages} variant="outlined" color="primary" page={currentPage} onChange={(event, page) => {
                  setCurrentPage(page);
                }} />
              </Box>
            </Box>
          )}
          {viewType === "grid" && (
            <Grid container spacing={2}>
              {clientData.map((client) => (
                <Grid item xs={12} sm={6} md={4} key={client.id}>
                  <StoreDetailCard {...client} />
                </Grid>
              )
              )}
            </Grid>
          )}
        </>
      )}

    </Box >
  )
}

export default ClientsData;