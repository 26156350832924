import React from "react";
import { useLocation } from "react-router-dom";
import RootLayout from "../components/RootLayout";
import OnboardStoreSteps from "../components/OnboardStoreSteps";
import { Box, Grid, Typography } from "@mui/material";
import useOnboardStore from "../hooks/useOnboardStore";


const OnboardStoreForm = (props) => {
    const { initiateAddStore, onboardedStoreDetails } = useOnboardStore(props);
    const location = useLocation();

    const handleSave = async (data) => {
        console.log('Form Data', data);
        await initiateAddStore(data);
    }

    return (
        <RootLayout containerClasses={"!p-0"}>
            <Grid style={{ justifyContent: "center" }} container>
                <Grid item xs={11} sm={9} md={5}>
                    <Typography sx={{ fontWeight: "bold", textAlign: "center", mt: 3, mb: 2 }} variant="h4" gutterBottom>
                        {location.pathname.includes("edit") ? "Edit Onboarded Store" : "Onboard Store"}
                    </Typography>
                    <Box className="mb-4">
                        <OnboardStoreSteps save={handleSave} />
                    </Box>
                </Grid>
            </Grid>
        </RootLayout>
    )
}

export default OnboardStoreForm;