import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import ConnectedStoores from "../pages/ConnectedStores";
import Reminders from "../pages/Reminders";
import Login from "../pages/Login";
import OnboardStoreForm from "../pages/OnboardStoreForm";
import PageNotFound from "../pages/PageNotFound";
import ClientDetails from "../pages/ClientDetails";

const router = createBrowserRouter([
  {
    path: "/",
    // element: <Home />,
    element: <ConnectedStoores />,
  },
  {
    path: "/connected-stores",
    element: <ConnectedStoores />,
  },
  {
    path: "/client-details",
    element: <ClientDetails />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/onboard-store-form",
    element: <OnboardStoreForm />,
  },
  {
    path: "/onboard-store-form/edit",
    element: <OnboardStoreForm />,
  },
  {
    path: "/reminders",
    element: <Reminders />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default router;