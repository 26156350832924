import { gql } from "@apollo/client";

const connectedStoreString = `
    _id
    storeName
    addressLine1
    addressLine2
    city
    state
    country
    pincode
    googleMapsUrl
    website
    instagram
    otherSocialMedia
    contactEmail
    contactPhoneNumber
    contactWhatsappNumber
    primaryContactName
    contactDesignation
    businessSector
    businessSegments
    specialtyAndNiche
    onboardingStatus
    nextFollowupDate
    remarks
    conclusionNotes
`;

const listConnectedStoresQuery = gql`
query ListConnectedStores(
  $page: Int, 
  $limit: Int, 
  $search: String, 
  $sortBy: String, 
  $sortOrder: String, 
  $filters: ConnectedStoreFilterType
) {
  listConnectedStores(
    page: $page, 
    limit: $limit, 
    search: $search, 
    sortBy: $sortBy, 
    sortOrder: $sortOrder, 
    filters: $filters
  ) {
    ${connectedStoreString}
  }
}
`;

const addConnectedStoreQuery = gql`
  mutation AddConnectedStore(
    $storeName: String!,
    $addressLine1: String,
    $addressLine2: String,
    $city: String,
    $state: String,
    $country: String,
    $pincode: String,
    $googleMapsUrl: String,
    $website: String,
    $instagram: String,
    $otherSocialMedia: String,
    $contactEmail: String,
    $contactPhoneNumber: String,
    $contactWhatsappNumber: String,
    $primaryContactName: String,
    $contactDesignation: String,
    $businessSector: [String],
    $businessSegments: [String],
    $specialtyAndNiche: [String],
    $onboardingStatus: [String],
    $nextFollowupDate: String,
    $remarks: String,
    $conclusionNotes: String
  ) {
    addConnectedStore(
      input: {
        storeName: $storeName,
        addressLine1: $addressLine1,
        addressLine2: $addressLine2,
        city: $city,
        state: $state,
        country: $country,
        pincode: $pincode,
        googleMapsUrl: $googleMapsUrl,
        website: $website,
        instagram: $instagram,
        otherSocialMedia: $otherSocialMedia,
        contactEmail: $contactEmail,
        contactPhoneNumber: $contactPhoneNumber,
        contactWhatsappNumber: $contactWhatsappNumber,
        primaryContactName: $primaryContactName,
        contactDesignation: $contactDesignation,
        businessSector: $businessSector,
        businessSegments: $businessSegments,
        specialtyAndNiche: $specialtyAndNiche,
        onboardingStatus: $onboardingStatus,
        nextFollowupDate: $nextFollowupDate,
        remarks: $remarks,
        conclusionNotes: $conclusionNotes
      }
    ) {
      ${connectedStoreString}
    }
  }
`;

const updateConnectedStoreQuery = gql`
  mutation UpdateConnectedStore(
    $storeId: ID!,
    $storeName: String,
    $addressLine1: String,
    $addressLine2: String,
    $city: String,
    $state: String,
    $country: String,
    $pincode: String,
    $googleMapsUrl: String,
    $website: String,
    $instagram: String,
    $otherSocialMedia: String,
    $contactEmail: String,
    $contactPhoneNumber: String,
    $contactWhatsappNumber: String,
    $primaryContactName: String,
    $contactDesignation: String,
    $businessSector: [String],
    $businessSegments: [String],
    $specialtyAndNiche: [String],
    $onboardingStatus: [String],
    $nextFollowupDate: String,
    $remarks: String,
    $conclusionNotes: String
  ) {
    updateConnectedStore(
      storeId: $storeId,
      input: {
        storeName: $storeName,
        addressLine1: $addressLine1,
        addressLine2: $addressLine2,
        city: $city,
        state: $state,
        country: $country,
        pincode: $pincode,
        googleMapsUrl: $googleMapsUrl,
        website: $website,
        instagram: $instagram,
        otherSocialMedia: $otherSocialMedia,
        contactEmail: $contactEmail,
        contactPhoneNumber: $contactPhoneNumber,
        contactWhatsappNumber: $contactWhatsappNumber,
        primaryContactName: $primaryContactName,
        contactDesignation: $contactDesignation,
        businessSector: $businessSector,
        businessSegments: $businessSegments,
        specialtyAndNiche: $specialtyAndNiche,
        onboardingStatus: $onboardingStatus,
        nextFollowupDate: $nextFollowupDate,
        remarks: $remarks,
        conclusionNotes: $conclusionNotes
      }
    ) {
      ${connectedStoreString}
    }
  }
`;

export { listConnectedStoresQuery, addConnectedStoreQuery, updateConnectedStoreQuery };
